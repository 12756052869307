import React, { useState } from 'react'
import { Link } from 'react-router-dom';
import { Link as ScrollLink } from 'react-scroll'
import ModeSwitch from '../ModeSwitch';
import Section from '../Section';

import './header.scss'

export default function Web3Header() {
  const [mobileToggle, setMobileToggle] = useState(false)
  const [resourceToggle, setResourceToggle] = useState(false)
  
  return (
    <>
      <Section tag='header' className="cs-site_header cs-style1 cs-primary_color">
        <div className='cs-white'>
        </div>

        <Section className="cs-main_header">
          <Section className="container-fluid">
            <Section className="cs-main_header_in">
              <Section className="cs-main_header_left">
                <Link to='/' className="cs-site_branding cs-accent_color">
                  <img src="/images/logo.webp" alt="Logo" className="cs-hide_dark" width={"64px"} height={"64px"} />
                  <img src="/images/logo.webp" alt="Logo" className="cs-hide_white" width={"64px"} height={"64px"} />
                  <img src="/images/catchcoin.webp" alt="Catchcoin Logo" className="cs-catchcoin-text" />
                </Link>

              </Section>
              <Section className="cs-main_header_right">
                <Section className="cs-nav">
                  <ul className="cs-nav_list" style={{ display: `${mobileToggle ? 'block' : 'none'}` }}>
                    <li><Link offset={-80} duration={500} to='/'>Home</Link></li>
                    <li><ScrollLink href='#about' to="about" spy={true} smooth={true} offset={-80} duration={500} onClick={() => setMobileToggle(false)}>About</ScrollLink></li>
                    <li><ScrollLink href='#roadmap' to="roadmap" spy={true} smooth={true} offset={-80} duration={500} onClick={() => setMobileToggle(false)}>Roadmap</ScrollLink></li>
                    <li><ScrollLink href='#tokenomics' to="tokenomics" spy={true} smooth={true} offset={-80} duration={500} onClick={() => setMobileToggle(false)}>Tokenomics</ScrollLink></li>
                    <li><ScrollLink href='#team' to="team" spy={true} smooth={true} offset={-80} duration={500} onClick={() => setMobileToggle(false)}>Team</ScrollLink></li>
                    <li><ScrollLink href='#faq' to="faq" spy={true} smooth={true} offset={-80} duration={500} onClick={() => setMobileToggle(false)}>FAQ</ScrollLink></li>
                    <li className="dropdownMenu">
                      {
                        mobileToggle ?
                          <span className="resources-nav" onClick={() => setResourceToggle(!resourceToggle)}>Resources</span>
                          :
                          <span className="resources-nav">Resources</span>
                      }
                      <ul className={mobileToggle && resourceToggle ? "dropdownContent dropdownResourcesMobile" : "dropdownContent"}>
                        <li><Link offset={-80} duration={500} to='https://portal.catchcoin.com/assets/Final_Portal_User_Guide.pdf' target="_blank">User Guide</Link></li>
                        <li><Link offset={-80} duration={500} to='pdf/Whitepaper.pdf' target="_blank">Whitepaper</Link></li>
                        <li><Link offset={-80} duration={500} to='https://github.com/EtherAuthority/KYC-Certificates/blob/main/CERTIFICATE%20of%20KYC%20-%20CatchCoin.pdf' target="_blank">KYC</Link></li>
                        <li><Link offset={-80} duration={500} to='https://etherauthority.io/catchcoin-catch-smart-contract-audit/' target="_blank">Audit</Link></li>
                      </ul>
                    </li>
                  </ul>
                  <span className={mobileToggle ? "cs-munu_toggle cs-toggle_active" : "cs-munu_toggle"} onClick={() => setMobileToggle(!mobileToggle)}><span></span></span>
                </Section>
                <Section className="cs-toolbox">
                  <ModeSwitch />
                </Section>
              </Section>
            </Section>
          </Section>
        </Section>
      </Section>
    </>
  )
}
