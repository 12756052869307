import React from 'react'
import parse from 'html-react-parser';
import './hero.scss'
import Section from '../Section';
import Button from '../Button';
import Slider from "react-slick";

export default function Web3Hero({ title, heroImageUrl, reflectionsImage, variant }) {
  const settings = {
    dots: true,
    arrows: false,
    fade: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    cssEase: "linear",
    lazyLoad: "ondemand",
    initialSlide: 0
  };

  return (
    <Section tag='section' className={`cs-hero cs-style1 ${variant ? variant : ''}`} id="hero" >
      <Section className="cs-hero_bg">
      </Section>

      <Section className="container">
        <Section className="cs-hero_text">
          <Section tag='h2' className="cs-hero_title cs-font_44 cs-font_36_sm cs-bold">{parse(title)}</Section>
          <Section className="cs-btn_group">
            <Button
              variant='cs-btn_filed cs-accent_btn mt-2 '
              btnLink='pdf/Whitepaper.pdf'
              btnText='Whitepaper'>
            </Button>
            <Button
              variant='cs-btn_filed cs-accent_btn mt-2 '
              btnLink='https://portal.catchcoin.com/assets/Final_Portal_User_Guide.pdf'
              btnText='User Guide'>
            </Button>
            <Button
              variant='cs-btn_filed cs-accent_btn mt-2 '
              btnLink='https://portal.catchcoin.com'
              btnText='Business Portal'>
            </Button>
          </Section>
          <Section className="cs-store_btn_group">
            <a href='https://play.google.com/store/apps/details?id=com.web3.catchcoin.dev' target='_blank' className='mt-2'>
              <img src='/images/google-store.png' alt="apple store"/>
            </a>
            <a href='/' target='_blank' className='mt-2'>
              <img src='/images/app-store.png' alt="apple store"/>
            </a>
          </Section>
        </Section>
        <Section className="cs-hero_img">
          <div className="slider-container">
            <Slider {...settings}>
              <div className='slider-item'>
                <img src={reflectionsImage} alt="generate foot traffic" />
              </div>
              
              <div className='slider-item'>
                <img src='images/catch-on-base.webp' alt="catch coin on base" style={{ textAlign: 'center' }} />
              </div>

              <div className='slider-item'>
                <div className='video-container'>
                  <iframe
                    src="https://www.youtube.com/embed/Z1kTVizYoJE"
                    allow="accelerometer; autoplay; encrypted-media; gyroscope;"
                    allowFullScreen={true}></iframe>
                </div>                
              </div>

              <div className='slider-item'>
                <img src={heroImageUrl} alt="catchcoin mobile app" style={{ textAlign: 'center' }} />
              </div>
            </Slider>
          </div>
        </Section>
      </Section>
    </Section>
  )
}
