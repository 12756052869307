import { Route, Routes } from "react-router-dom";
import ErrorPage from "./components/ErrorPage";
import Index from "./components/Pages/Index";
import { Link } from 'react-router-dom';
import Terms from "./components/Pages/Terms";
import Privacy from "./components/Pages/Privacy";
import { ToastContainer } from 'react-toastify';
import CookieConsent from "react-cookie-consent";

function App() {
  return (
    <>
      <Routes>
        <Route path="/" element={<Index />} />
        <Route path="/terms" element={<Terms />} />
        <Route path="/privacy" element={<Privacy />} />
        <Route path="*" element={<ErrorPage />} />
      </Routes>
      <CookieConsent
        enableDeclineButton={true}
        flipButtons
        onAccept={(acceptedByScrolling) => {
          window.consentGranted()
        }}
      >
        This website uses cookies to enhance the user experience and measure performance. Details in our <Link offset={-80} duration={500} to='/privacy' target="_blank">Privacy Policy</Link>.
      </CookieConsent>
      <ToastContainer /> 
    </>
  );
}

export default App;
