import React from 'react'
import { useInView } from "react-intersection-observer";
import './lazyload.scss';

export default function LazyLoadSection({ animationType, children, ...props }) {
    const { ref, inView } = useInView({
        triggerOnce: true,
        threshold: 0.5,
      });

    // Define the animation classes based on the `animationType` prop
    let animationClass = '';

    switch (animationType) {
        case 'fade':
            animationClass = 'fade-in';
            break;
        case 'slide':
            animationClass = 'slide-in';
            break;
        case 'zoom':
            animationClass = 'zoom-in';
            break;
        default:
            animationClass = 'fade-in'; // default to fade if no valid type is provided
            break;
    }
      
    return (
        <div 
            ref={ref}
            className={`lazy-load-section ${animationClass} ${inView ? 'visible' : ''}`} 
            {...props}
        >
            {inView ? children : "Loading..."}
        </div>
    );
}