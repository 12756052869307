import React, { useEffect } from 'react'
import { pageTitle } from '../../helper'
import Section from '../Section'
import SectionHeading from '../SectionHeading'
import Spacing from '../Spacing'

export default function Terms() {
  pageTitle('Terms');
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <>
      <Section tag='section' id='about'>
        <Spacing lg='100' md='70' />
        <Section className="container">
          <Section className="row align-content-center">
            <Section className="col">
              <Section className='cs-right_space_40'>
                <SectionHeading
                  title='Terms & Conditions'
                />
                <Spacing lg='20' md='20' />
                <Section tag='div'>
                  <p>
                    <strong>Terms & Conditions - catchcoin</strong>
                  </p>
                  <p>
                    <em>Last Updated: 20-03-2024</em>
                  </p>
                  <p>These Terms of Service ("Terms") govern your access to and use of our services, including but not limited to the catchcoin cryptocurrency and community projects (collectively referred to as "Services").</p>
                  <ol>
                    <li>
                      <strong>Acceptance of Terms</strong>
                      <p>By accessing or using our Services, you agree to be bound by these Terms and any additional terms that may be presented in connection with specific Services. If you do not agree to these Terms, you may not use our Services.</p>
                    </li>
                    <li>
                      <strong>Token Ownership</strong>
                      <p>Ownership of the Token does not represent any equity, ownership, or rights to any assets or property. The Token is solely a digital representation of value on the BASE Chain ("BASE").</p>
                    </li>
                    <li>
                      <strong>Volatility and Risk</strong>
                      <p>The value of the Token may fluctuate significantly and rapidly. You acknowledge and accept the risks associated with holding, purchasing, or trading the Token, and agree that the project team, developers, and associated parties shall not be held liable for any losses incurred.</p>
                    </li>
                    <li>
                      <strong>No Guarantee of Returns</strong>
                      <p>There is no guarantee of any returns or profits from holding or trading the Token. The Token's value may decrease to zero, and past performance is not indicative of future results.</p>
                    </li>
                    
                    <li>
                      <strong>Regulatory Compliance</strong>
                      <p>You are solely responsible for ensuring compliance with any applicable laws, regulations, or restrictions in your jurisdiction regarding the purchase, holding, or use of the Token.</p>
                    </li>

                    <li>
                      <strong>No Investment Advice</strong>
                      <p>The information provided about the Token, including but not limited to whitepapers, websites, and social media channels, is for informational purposes only and should not be construed as investment advice. You should conduct your own research and seek professional advice before making any investment decisions.</p>
                    </li>

                    <li>
                      <strong>Security</strong>
                      <p>You are responsible for maintaining the security of your wallet and private keys associated with the Token. The project team, developers, and associated parties shall not be liable for any loss of funds or assets due to security breaches, hacks, or negligence on your part.</p>
                    </li>
                    <li>
                      <strong>No Warranty</strong>
                      <p>The Token is provided on an "as is" and "as available" basis without any warranties of any kind, express or implied. The project team, developers, and associated parties disclaim all warranties, including but not limited to the implied warranties of merchantability, fitness for a particular purpose, and non-infringement.</p>
                    </li>
                    <li>
                      <strong>Changes and Updates</strong>
                      <p>These Terms may be updated or revised at any time without prior notice. It is your responsibility to review these Terms periodically for any changes. Your continued use of the Token after any modifications constitutes acceptance of the updated Terms.</p>
                    </li>
                    <li>
                      <strong>Community Conduct</strong>
                      <p>Users agree to abide by all applicable laws and regulations while engaging in the community. Prohibited activities include but are not limited to fraud, market manipulation, unauthorized access, and any actions that violate the rights of catchcoin or other users.</p>
                    </li>
                    <li>
                      <strong>Restricted Countries</strong>
                      <p>Catchcoin's services are not available in countries where cryptocurrency and DeFi services are banned by local regulations. Users are responsible for ensuring compliance with their local laws and regulations. By using our Services, you confirm that you are not located in a country where cryptocurrency and DeFi services are prohibited. The following countries are currently banned from accessing Catchcoin's services: China, Algeria, Bangladesh, Egypt, Bolivia, Morocco, Russia, Qatar, Turkey, Iraq, Vietnam, India, Iran, Nepal, Indonesia, Afghanistan, Colombia, Ecuador, Ghana, Macedonia, Nigeria, Central African Republic, Cuba, and Mali.</p>
                    </li>
                    <li>
                      <strong>Community Projects</strong>
                      <p>Catchcoin may facilitate community projects related to the CATCH cryptocurrency. Participation in these projects is voluntary, and users are encouraged to thoroughly assess the associated risks and terms before participating.</p>
                    </li>
                    <li>
                      <strong>User Conduct</strong>
                      <p>Users agree to abide by all applicable laws and regulations when using our Services. Prohibited activities include but are not limited to fraud, market manipulation, unauthorized access, and any actions that violate the rights of Catchcoin or other users.</p>
                    </li>
                    <li>
                      <strong>Privacy Policy</strong>
                      <p>Our Privacy Policy governs the collection and use of user information. By using our Services, you consent to the terms outlined in our Privacy Policy.</p>
                    </li>
                    <li>
                      <strong>Limitation of Liability</strong>
                      <p>catchcoin is not liable for any direct, indirect, incidental, special, or consequential damages resulting from the use or inability to use our Services.</p>
                    </li>
                    <li>
                      <strong>Changes to Terms</strong>
                      <p>catchcoin reserves the right to update or modify these Terms at any time. Users are responsible for regularly reviewing the Terms. Continued use of our Services after any changes constitute acceptance of the revised Terms.</p>
                    </li>
                  </ol>
                  <p>If you have any questions or concerns regarding these Terms, please contact us at <a href="mailto:info@catchcoin.com">info@catchcoin.com</a> or visit our website at <a href="https://www.catchcoin.com">www.catchcoin.com</a>. </p>
                  <p>Thank you for choosing Catchcoin!</p>
                </Section>
              </Section>
              <Spacing lg='0' md='40' />
            </Section>
          </Section>
        </Section>
        <Spacing lg='100' md='70' />
      </Section>
    </>
  )
}
